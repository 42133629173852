body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  line-height: 1.4;
  color: #222;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4 {
  font-family: 'Roboto Condensed', sans-serif;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/** Placeholder */
::-webkit-input-placeholder {
  color: #aeaeae;
}

::-moz-placeholder {
  color: #aeaeae;
}

:-ms-input-placeholder {
  color: #aeaeae;
}

:-moz-placeholder {
  color: #aeaeae;
}

/** react-datepicker */
.react-datepicker__input-container {
  width: 120px;
}

.react-datepicker__input-container input {
  border: none;
  padding: 4px;
  font-size: inherit;
  font-family: inherit;
  outline: none;
  padding-left: 22px;
  width: 100%;
  cursor: pointer;
  margin: -4px;
}

.react-datepicker__triangle {
  left: auto !important;
  right: 40px !important;
}

a {
  color: #ec7601;
}

.ProseMirror {
  font-size: inherit;
  font-family: inherit;
  border: 1px solid transparent;
  width: 100%;
  max-width: 100%;
  outline: none;
  transition: all 0.25s ease-in-out;
  background: #f7f7f7;
  padding: 8px 10px;
}

.ProseMirror:focus {
  box-shadow: 0 0 5px rgba(255, 142, 1, 1);
  border: 1px solid rgba(255, 142, 1, 1);
}

.ProseMirror p {
  margin: 0 0 1em 0;
}

.ProseMirror p:last-child {
  margin: 0;
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
